import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

function Service() {

  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null); // Reference to the video element
  const [counter, setCounter] = useState(500);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    phone: "",
    message: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the backend using Axios
      const response = await axios.post("https://dfitnesss.com/contact.php", formData);

      alert(response.data?.message)
      setFormData({
        name: "",
        email: "",
        gender: "",
        phone: "",
        message: "",
      })
      // Handle successful submission (e.g., show a success message)
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      // Handle any errors (e.g., show an error message)
      console.error("Error submitting form:", error);
    }
  };

  const handleToggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted; // Toggle mute
    }
    setIsMuted(!isMuted); // Update the state

  };


  useEffect(() => {
    // Only animate if the number is less than 1568
    if (counter < 1568) {
      const interval = setInterval(() => {
        setCounter(prevCounter => Math.min(prevCounter + 1, 1568));
      }, 20); // Increment every 20ms (adjust speed as needed)


      return () => clearInterval(interval);
    }
  }, [counter]);

  return (
    <>
      {/* Hero Section */}
      <div id='service' className="relative w-full lg:py-20 py-10 bg-opacity-55 bg-black bg-cover bg-center" style={{ backgroundImage: "url('/ser.jpg')" }}>
        {/* Overlay to darken the background image */}
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-black opacity-70 z-10" style={{ filter: 'brightness(0.6)' }}></div>

        <div className="flex items-center justify-center h-full text-center text-white px-6 relative z-20">
          <div className="max-w-4xl">
            {/* Main heading */}
            <p className="text-3xl sm:text-5xl font-extrabold leading-tight">
              Best Packages with 50% Offer
            </p>

            {/* Package Grid */}
            <div className="mt-8 space-y-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8 text-lg sm:text-2xl font-light">
                {/* <div className="flex flex-col items-center text-center bg-white bg-opacity-20 p-6 rounded-xl">
            <h3 className="text-xl font-semibold mb-2 ]">21 Days Challenge</h3>
            <p className="text-gray-300 font-semibold">Above 4 kg weight loss</p>
            <p className="text-red-500 text-3xl font-extrabold  mt-4">₹999</p>
          </div> */}
                <div className="flex flex-col items-center text-center bg-white bg-opacity-20 p-6 rounded-xl">
                  <h3 className="text-xl font-semibold mb-2">30 Days Challenge</h3>
                  <p className="text-gray-300 font-semibold">Above 6 kg weight loss</p>
                  <p className="text-red-500 text-3xl font-extrabold  mt-4">₹999</p>
                </div>
                {/* <div className="flex flex-col items-center text-center bg-white bg-opacity-20 p-6 rounded-xl">
            <h3 className="text-xl font-semibold mb-2">45 Days Challenge</h3>
            <p className="text-gray-300 font-semibold">Above 8 kg weight loss</p>
            <p className="text-red-500 text-3xl font-extrabold  mt-4">₹1499</p>
          </div> */}
                <div className="flex flex-col items-center text-center bg-white bg-opacity-20 p-6 rounded-xl">
                  <h3 className="text-xl font-semibold mb-2">60 Days Challenge</h3>
                  <p className="text-gray-300 font-semibold">Above 10 kg weight loss</p>
                  <p className="text-red-500 text-3xl font-extrabold mt-4">₹1799</p>
                </div>
              </div>
            </div>

            {/* Key offer highlight */}
            <p className="mt-4 text-lg sm:text-4xl font-extrabold text-[rgb(253,233,50)]">
              Join now with a 50% offer
            </p>

            {/* CTA Button */}
            <a href="#program" className="inline-block mt-10 bg-red-600 text-white py-2 px-6 text-lg font-medium transition transform hover:scale-105">
              Start Your Transformation
            </a>
          </div>
        </div>
      </div>





      {/* How It Works Section */}
      <section className="py-16 bg-white text-center container mx-auto">
        <h2 className="text-5xl font-extrabold mb-6" style={{ fontFamily: '"Orelega One", serif' }}>How It Works</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {/* Exercise Card */}
          <div className="p-6">
            <div className="bg-white shadow-lg rounded-lg p-8  transition-shadow duration-300 hover:transform hover:scale-105">
              <div className="mb-4">
                <img src="/i.jpg" alt="Exercise" className="w-full h-full mx-auto" />
              </div>
              <h3 className="text-xl font-bold mb-2">Tailored Exercise Plans</h3>
              <p className="text-lg text-gray-600">
                We design personalized exercise regimens based on your current fitness level and goals, using a combination of strength, cardio, and flexibility exercises.
              </p>
            </div>
          </div>

          {/* Nutrition Card */}
          <div className="p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 hover:shadow-xl transition-shadow duration-300 hover:transform hover:scale-105">
              <div className="mb-4">
                <img src="/nut.jpg" alt="Nutrition" className="w-full h-full mx-auto" />
              </div>
              <h3 className="text-xl font-bold mb-2">Personalized Nutrition</h3>
              <p className="text-lg text-gray-600">
                Along with your workouts, we provide a tailored nutrition plan that helps fuel your body for maximum fat loss and sustainable energy.
              </p>
            </div>
          </div>

          {/* Progress Tracking Card */}
          <div className="p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 hover:shadow-xl transition-shadow duration-300 hover:transform hover:scale-105">
              <div className="mb-4">
                <img src="/track.jpg" alt="Tracking" className="w-full h-full mx-auto" />
              </div>
              <h3 className="text-xl font-bold mb-2">Ongoing Support & Tracking</h3>
              <p className="text-lg text-gray-600">
                We provide continuous support throughout the program, helping you track your progress and making adjustments to keep you on the path to success.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* benifit */}


      <div className="py-6">
        <h2 className="text-5xl font-extrabold mb-6" style={{ fontFamily: '"Orelega One", serif' }}>
          Fitness Benefits
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">

          {/* Girls Benefits */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold mb-4 text-red-600 text-center">For Girls</h3>
            <div className="grid grid-cols-1 gap-6 px-10">
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-female text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">PCOD</h4>
                  <p className="text-sm">Balanced hormone levels, improved menstrual health.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-heart text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">PCOS</h4>
                  <p className="text-sm">Regulates metabolism, improves insulin sensitivity.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-medkit text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Diabetes</h4>
                  <p className="text-sm">Better blood sugar control and insulin management.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-baby text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Pregnancy Weight Gain</h4>
                  <p className="text-sm">Helps manage healthy weight during pregnancy.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-th-large text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Thyroid</h4>
                  <p className="text-sm">Supports thyroid health and metabolic function.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-cogs text-3xl text-red-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Cholesterol</h4>
                  <p className="text-sm">Helps maintain healthy cholesterol levels.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Boys Benefits */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold mb-4 text-blue-600 text-center">For Boys</h3>
            <div className="grid grid-cols-1 gap-6 px-10">
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-heartbeat text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Diabetes</h4>
                  <p className="text-sm">Enhances insulin sensitivity and blood sugar management.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-heart text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Cholesterol</h4>
                  <p className="text-sm">Regulates cholesterol levels for better cardiovascular health.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-tint text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Blood Pressure</h4>
                  <p className="text-sm">Helps lower blood pressure, promoting better heart health.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-bolt text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Testosterone Boost</h4>
                  <p className="text-sm">Enhances energy, strength, and overall vitality.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-bed text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Back Pain</h4>
                  <p className="text-sm">Strengthens back muscles and reduces pain and discomfort.</p>
                </div>
              </div>
              <div className="flex items-center p-4 border border-gray-200 rounded-lg hover:bg-gray-100 transition-all duration-300">
                <i className="fas fa-bone text-3xl text-blue-600 mr-4"></i>
                <div>
                  <h4 className="font-bold text-xl">Knee Pain</h4>
                  <p className="text-sm">Improves knee joint mobility and reduces pain.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>





      {/* Results & Testimonials Section */}
      <section id='result' className="py-20 bg-gray-50 container mx-auto">
        <h2 className="text-5xl text-center font-extrabold mb-6" style={{ fontFamily: '"Orelega One", serif' }}>
          Real Results from Real People
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 py-8">
          {/* Client 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client1.jpg" alt="Client 1" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "This program transformed my life. I lost 10kg in 45 days and now feel healthier and more confident!"
            </p>
          </div>

          {/* Client 2 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client.jpg" alt="Client 2" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "I never thought it was possible to lose weight so quickly and sustainably. The workouts were challenging but effective!"
            </p>
          </div>

          {/* Client 3 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client3.jpg" alt="Client 3" className="w-full h-80 object-contain object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "The personalized plan made all the difference. I was able to stay motivated and reach my goals!"
            </p>
          </div>

          {/* Client 4 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client4.jpg" alt="Client 4" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "The trainers are so supportive and knowledgeable. They helped me through every step of my journey."
            </p>
          </div>

          {/* Client 5 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client5.jpg" alt="Client 5" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "I've tried other programs, but this one really works. I saw real results in just 45 days!"
            </p>
          </div>

          {/* Client 6 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client6.jpg" alt="Client 6" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "The combination of exercise and nutrition guidance was exactly what I needed to change my habits."
            </p>
          </div>

          {/* Client 7 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client7.jpg" alt="Client 7" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "The structure of the program kept me accountable, and I loved seeing my progress each week."
            </p>
          </div>

          {/* Client 8 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client8.jpg" alt="Client 8" className="w-full h-80 object-cover object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "I never felt alone during the process. The support and motivation were incredible!"
            </p>
          </div>

          {/* Client 9 - Video */}


          {/* Client 10 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/clien.jpg" alt="Client 10" className="w-full h-80 object-contain object-top rounded-t-lg bg-contain" />
            <p className="text-lg text-gray-600 mt-4">
              "This journey has been life-changing. I achieved my weight loss goals and gained confidence!"
            </p>
          </div>

          {/* Client 11 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client11.jpg" alt="Client 11" className="w-full h-80 object-contain object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "The diet plan was easy to follow, and I didn't feel deprived. It made a huge difference."
            </p>
          </div>

          {/* Client 12 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client12.jpg" alt="Client 12" className="w-full h-80 object-contain object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "Seeing the progress every week motivated me to keep going. I feel healthier and happier!"
            </p>
          </div>

          {/* Client 13 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/client13.jpg" alt="Client 13" className="w-full h-80 object-contain object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              "I didn't just lose weight; I gained a healthier lifestyle that I can maintain."
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <img src="/13.jpg" alt="Client 13" className="w-full h-80 object-contain object-top rounded-t-lg" />
            <p className="text-lg text-gray-600 mt-4">
              This program helped me lose weight and change my lifestyle. I am in better shape now!"
            </p>
          </div>
          <div className="bg-white p-6  rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <video poster='/ss1.jpg'
              ref={videoRef}
              className="w-full h-96 object-top object-contain rounded-t-lg"

              controls={true}
              src={'/vid (1).webm'}
              loop
              muted={isMuted}
            ></video>
          </div>
          <div className="bg-white p-6  rounded-lg shadow-lg hover:transform hover:scale-105 transition-transform duration-300" data-aos="fade-up">
            <video poster='/ss.jpg'
              ref={videoRef}
              className="w-full h-96 object-top object-contain rounded-t-lg"

              controls={true}
              src={'/vv.mp4'}
              loop
              muted={isMuted}
            ></video>
          </div>
        </div>
      </section>


      {/* Call to Action Section */}

      <div id="program" className="mb-8 bg-transparent font-extrabold text-4xl text-center md:text-left">
        <h2 className="font-extrabold text-4xl text-center text-gray-600">Ready to Start Your Transformation?</h2>
        <p className='text-gray-600 text-sm text-center p-3 '>
          Join our proven 45-day weight loss program and lose 10kg with expert guidance and support.
        </p>

        <p className="text-red-700 text-5xl text-center mt-4">
          {counter}
        </p>
        <h6 className=' text-red-500 text-center text-4xl'> People Achieve Body Transformation</h6>
      </div>
      <section
        className="py-16 text-white bg-cover bg-center  flex justify-end items-center  relative"
        style={{ backgroundImage: "url('/bg.png')" }}
      >
        <div className="w-full md:w-1/2 flex justify-center md:justify-end  px-5 pt-20 lg:m-20">
          {/* Content and Form Container */}
          <div className="bg-black bg-opacity-80 p-8 rounded-lg shadow-lg max-w-lg w-full overflow-x-hidden" data-aos="fade-up" >
            {/* Content */}


            {/* Form */}
            <form className="mt-7 space-y-4 text-left w-full sm:w-auto" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-white">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name='name'
                  className="mt-1 block w-full p-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-white">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name='email'
                  className="mt-1 block w-full p-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="gender" className="block text-sm font-medium text-white">
                  Gender
                </label>
                <div className="mt-1 flex items-center space-x-4">
                  <label className="flex items-center text-sm text-white">
                    <input
                      type="radio"
                      name="gender"

                      value="male"
                      className="h-4 w-4 text-red-500 border-gray-600 focus:ring-red-500"
                      checked={formData.gender === "male"}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Male</span>
                  </label>
                  <label className="flex items-center text-sm text-white">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      className="h-4 w-4 text-red-500  border-gray-600 focus:ring-red-500"
                      checked={formData.gender === "female"}
                      onChange={handleChange}
                    />
                    <span className="ml-2">Female</span>
                  </label>
                </div>
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-white">
                  Phone Number
                </label>
                <input
                  type="phone"
                  id="phone"
                  name='phone'
                  className="mt-1 block w-full p-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
                  placeholder="Enter your phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                />

              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-lg  font-semibold text-white">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full p-3 mt-2 border border-gray-300 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
                  placeholder="Your message here..."
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-red-600 text-white py-2 px-4  shadow-md hover:bg-red-700 transition"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>

    </>
  );
}

export default Service;
